.card {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 16px;
  margin: 16px;
  text-align: center;
  width: 100%; /* Responsive width will be set via media queries in App.css */

  overflow: hidden;
  position: relative;
}

@keyframes flashingGreen {
  0%, 100% { background-color: green; }
  50% { background-color: transparent; }
}

@keyframes flashingRed {
  0%, 100% { background-color: red; }
  50% { background-color: transparent; }
}

#pumpButton {
  animation: flashingGreen 1s infinite;
  color: white; /* Set text color */
  border: none; /* Remove border */
  padding: 10px; /* Add some padding */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Adjust font size */
  /* Add more styling as needed */
}

#dumpButton {
  animation: flashingRed 1s infinite;
  color: white; /* Set text color */
  border: none; /* Remove border */
  padding: 10px; /* Add some padding */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Adjust font size */
  /* Add more styling as needed */
}

#bribeButton {
  animation: flashingGreen 1s infinite;
  color: white; /* Set text color */
  border: none; /* Remove border */
  padding: 10px; /* Add some padding */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Adjust font size */
  /* Add more styling as needed */
}

#nobribeButton {
  animation: flashingRed 1s infinite;
  color: white; /* Set text color */
  border: none; /* Remove border */
  padding: 10px; /* Add some padding */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Adjust font size */
  /* Add more styling as needed */
}





/* Larger screens */
@media (min-width: 600px) {
  .card {
    flex: 0 0 calc(50% - 10px); /* 2 cards per row */
  }
}

@media (min-width: 1024px) {
  .card {
    flex: 0 0 calc(33.33% - 10px); /* 3 cards per row */
  }
}

@media (min-width: 1280px) {
  .card {
    flex: 0 0 calc(25% - 10px); /* 4 cards per row */
  }
}


.card-image {
  width: 100%; /* Ensures the image takes full width of the card */
  height: auto; /* Initially set height to auto */
  object-fit: cover; /* Ensures the image covers the available area without stretching */
  max-height: 300px; /* Adjust this value as needed */
  border-radius: 4px; /* Optional: to match card's border-radius */
}

.card-info {
  margin-top: 8px;
}

.card-name {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.animated-image {
  position: absolute;
  transition: top 2s, left 2s, opacity 2s;
}

.info-box button {
  margin: 0 10px;
}

.info-bubble {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  background-color: white;
  color: black;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  z-index: 10;
}

.card:hover .info-bubble {
  display: block;
}