.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url('./runningdog.gif');
  background-size: cover; /* Cover the entire viewport */
  background-repeat: no-repeat;
  background-position: center;

}

body {
  cursor: none !important;
}


.body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background: #f5f5f5;
  font-size: 14px;

}


@media (max-width: 768px) {
  .App {
    background-image: url('./runningdog.gif');
    /* Adjust other properties if needed */
  }
}




.header {
  background: #003366;
  color: #fff;
  padding: 1rem;
  text-align: center;
  display: flex;
  align-items: center; /* Vertically center the content */
  justify-content: center; /* Horizontally center the content */
}

.header-image {
  max-height: 100px; /* Adjust as needed */
  max-width: 100%; /* Ensures the image doesn't exceed the header width */
  height: auto; /* Maintains aspect ratio */
  width: auto; /* Maintains aspect ratio */
}


.main-content {
  flex: 1;
  display: flex; /* Added display flex for proper alignment */
  flex-direction: row;
  overflow: auto;
}

.sidebar {
  width: 20%;
  background-color: #f0f0f0;
  padding: 20px;
  box-sizing: border-box;
}

.content {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-around; /* Adjust alignment */
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Align items with space between them */
  background: #003366;
  color: #fff;
  text-align: center;
  padding: 1rem;
}

.disclaimer-btn {
  background-color: #4CAF50; /* Green background */
  color: white;
  padding: 10px 20px;
  margin-left: 20px; /* Space on the left */
  border: none;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s; /* Smooth transition for hover effect */
}




.card {
  background: #fff;
  margin-bottom: 2rem;
  padding: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  flex: 0 0 calc(20% - 10px);
  margin: 5px;
}

.card-header {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.card-content {
  font-size: 1rem;
}

.App-footer {
  padding: 20px;
  background-color: #333;
  color: white;
  text-align: left;
  position: fixed; /* Changed to fixed for consistent footer positioning */
  bottom: 0;
  left: 0;
  width: 100%;
}

.Footer-logo {
  height: 50px;
  margin-right: 20px;
}

.footer-content {
  display: flex;
  align-items: center;
}

.ribbon {
  background-color: #999;
  color: white;
  padding: 20px 0;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.ribbon span {
  position: absolute;
  white-space: nowrap;
  display: inline-block;
  animation: scroll-text 450s linear infinite;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.fullscreen-gif {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.fullscreen-gif img {
  max-width: 90%; /* Adjust as needed */
  max-height: 90%; /* Adjust as needed */
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}